import Layout from "../components/Layout";
import '../styles/globals.css'
import { Provider } from "react-redux";
import store from "../redux/store";
import { persistor } from "../redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { createWrapper } from "next-redux-wrapper";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripeKeys } from "../components/global";

const stripePromise = loadStripe(stripeKeys.publishable_Key);

function MyApp({ Component, pageProps }) {
  return <>
    <Provider store={store} session={pageProps.session} >
      <PersistGate loading={null} persistor={persistor}>
        <Layout>
          <Elements stripe={stripePromise}>
            <Component {...pageProps} />
          </Elements>
        </Layout>
      </PersistGate>
    </Provider>
  </>
}

const makestore = () => store;
const wrapper = createWrapper(makestore);
export default wrapper.withRedux(MyApp);