
import { SAVE_LOGIN, LOGIN_ERROR, CLEAR_LOGIN, UPDATE_LOGIN, UPDATE_PROFILE } from "../constants/actionTypes";

const initialState = {
    userdata: null,
    isSubscribed: false,
    token: null,
    islogged: false,
    errorLogin: undefined,
}

export default function user(state = initialState, action) {
    switch (action.type) {
        case SAVE_LOGIN:
            return {
                ...state,
                userdata: action.data,
                token: action.token,
                isSubscribed: action.subscription,
                islogged: true,
                errorLogin: undefined
            }
        case UPDATE_LOGIN:
            return {
                ...state,
                isSubscribed: action.data,
            }
        case UPDATE_PROFILE:
            return {
                ...state,
                userdata: action.data,
            }
        case LOGIN_ERROR:
            return {
                ...state,
                userdata: null,
                token: null,
                islogged: false,
                errorLogin: action.data,
            }
        case CLEAR_LOGIN:
            return {
                ...state,
                userdata: null,
                token: null,
                islogged: false,
                errorLogin: undefined,
            }
        default:
            return state
    }
}

